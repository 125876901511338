<template>
  <div id="Modal">
    <b-modal
      :id="id"
      @ok="handleOk"
      @cancel="handleCancel"
      cancelTitle="Cancelar"
      cancelVariant="outline-secondary"
      centered
      header-bg-variant="dark"
      header-text-variant="light"
      okTitle="Salvar"
      okVariant="danger"
      ref="modal"
      size="xl"
      title="Cadastro de Composição"
    >
      <form @submit.stop.prevent="handleSubmit" ref="form">
        <h2 class="modal__title">Produto Acabado</h2>
        <div class="formBordas">
          <ProductSearchComp
            class="campoObrigatorio"
            CampoObrigatorio="true"
            :limpaDados="limpaDadosProdutoAcabado"
            :disabled="disabledProdutoAcabado"
            :labelCode="produtoAcabado.pve_codigo.toString()"
            :labelCodeRef="produtoAcabado.pve_codigo_referencia"
            :labelCodeBarras="produtoAcabado.pve_codigo_barras"
            :labelName="produtoAcabado.product_complete_name"
            @update:value="produtoAcabado = $event"
            :isQtdeVisible="true"
          >
            <template #qtde>
              <label>Quantidade produzida por receita:</label>
              <v-text-field
                :value="getFloatValue(quantity)"
                @input="quantity = $event"
                class="right-input campoObrigatorio"
                solo
                type="number"
              />
            </template>
          </ProductSearchComp>
          <b-row class="submit">
            <v-btn v-if="!disabledProdutoAcabado" @click="selectItem"
              >Selecionar</v-btn
            >
            <v-btn v-else disabled>Selecionar</v-btn>
          </b-row>
        </div>

        <b-row>
          <b-col cols="12" sm="10" md="10">
            <label style="margin-bottom: 26px"></label>
            <v-text-field
              solo
              disabled
              v-model="produtoFinalSelect.product_complete_name"
            >
            </v-text-field>
          </b-col>
          <b-col cols="12" sm="2" md="2" class="botaoAlterar">
            <v-btn block @click="alteraItem" :disabled="!disabledProdutoAcabado"
              >Alterar</v-btn
            >
          </b-col>
        </b-row>

        <h2 class="modal__title">Matéria-prima</h2>
        <div class="formBordas">
          <ProductSearchComp
            class="campoObrigatorio"
            CampoObrigatorio="true"
            :limpaDados="limpaDadosMateriaPrima"
            :disabled="
              disabledProdutoMateriaPrima || disabledEditProdutoMateriaPrima
            "
            :labelCode="produtoMateriaPrima.pve_codigo.toString()"
            :labelCodeRef="produtoMateriaPrima.pve_codigo_referencia"
            :labelCodeBarras="produtoMateriaPrima.pve_codigo_barras"
            :labelName="produtoMateriaPrima.product_complete_name"
            @update:value="produtoMateriaPrima = $event"
          />
          <b-row>
            <b-col cols="12" sm="4" md="4">
              <label>Unidade de medida:</label>
              <v-text-field
                solo
                disabled
                v-model="produtoMateriaPrima.measurement_unit.unm_descricao"
              >
              </v-text-field>
            </b-col>
            <b-col cols="12" sm="2" md="2">
              <label>Quantidade:</label>
              <v-text-field
                class="right-input campoObrigatorio"
                solo
                type="number"
                :disabled="disabledProdutoMateriaPrima"
                v-model="produtoMateriaPrima.quantidade"
              >
                <template v-slot:prepend-inner>
                  <img
                    style="vertical-align:top;"
                    width="20"
                    height="49"
                    alt=""
                    src="../../assets/faixaCampoObrigatorio.png"
                  />
                </template>
              </v-text-field>
            </b-col>
            <b-col cols="12" sm="2" md="2">
              <label>Valor unitário (R$):</label>
              <v-text-field
                class="right-input"
                solo
                disabled
                v-model="
                  Number(
                    produtoMateriaPrima.product_variety.prv_valor_compra,
                  ).toFixed(2)
                "
              >
              </v-text-field>
            </b-col>
            <b-col cols="12" sm="2" md="2">
              <label>Valor total (R$):</label>
              <v-text-field
                class="right-input"
                solo
                disabled
                v-model="produtoMateriaPrima.valorTotal"
              >
              </v-text-field>
            </b-col>
            <b-col cols="12" sm="2" md="2">
              <label>Controla Estoque:</label>
              <v-checkbox
                class="checkbox"
                v-model="produtoMateriaPrima.pve_controla_estoque"
                :disabled="disabledProdutoMateriaPrima"
                :label="
                  `${
                    produtoMateriaPrima.pve_controla_estoque == 1
                      ? ' Sim'
                      : ' Não'
                  }`
                "
              ></v-checkbox>
            </b-col>
          </b-row>
          <b-row class="submit">
            <v-btn :disabled="disabledProdutoMateriaPrima" @click="addItem"
              >Adicionar</v-btn
            >
          </b-row>
        </div>

        <template>
          <v-data-table
            hide-default-footer
            :headers="headers"
            :items="produtoItens"
            :items-per-page="produtoItens.length"
            class="elevation-1"
          >
            <template v-slot:no-data>
              <h6 class="mb-1 mt-1 no-data">
                Nenhum resultado encontrado.
              </h6>
            </template>

            <template v-slot:top>
              <v-toolbar flat>
                <v-dialog v-model="dialogDelete" max-width="725px">
                  <v-card>
                    <v-card-title class="text-h5">
                      Deseja realmente remover a matéria-prima desta
                      composição?</v-card-title
                    >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete"
                        >Cancelar</v-btn
                      >
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="deleteItemConfirm"
                        >OK</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>

            <template v-slot:item.quantidade="{item}">
              <div class="align-right">
                <span>{{ item.quantidade }}</span>
              </div>
            </template>

            <template v-slot:item.valorTotal="{item}">
              <div class="align-right">
                <span>{{ valorMonetario(item.valorTotal) }}</span>
              </div>
            </template>

            <template v-slot:item.prv_valor_compra="{item}">
              <div class="align-right">
                <span>{{ valorMonetario(item.prv_valor_compra) }}</span>
              </div>
            </template>

            <template v-slot:item.pve_controla_estoque="{item}">
              <div class="align-center">
                <span v-if="item.pve_controla_estoque == 0">Não</span>
                <span v-if="item.pve_controla_estoque == 1">Sim</span>
              </div>
            </template>

            <template v-if="mostraTotal" v-slot:body.append>
              <tr>
                <td colspan="4"></td>
                <td colspan="1" style="text-align:right">
                  <span>Total (R$):</span>
                </td>
                <td colspan="1" class="align-right">
                  <span>{{ totalValue() }}</span>
                </td>
              </tr>
            </template>

            <template v-slot:item.actions="{item}">
              <v-icon small class="mr-2" @click="editItem(item)">
                mdi-pencil
              </v-icon>
              <v-icon small @click="deleteItem(item)">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </template>
      </form>
    </b-modal>
  </div>
</template>

<script>
import ProductSearchComp from '@/components/search-components/ProductSearchComp';
import swal from 'sweetalert2';
import apiInstance from '../../services/api/config';

export default {
  name: 'Modal',
  components: {
    ProductSearchComp,
  },
  props: ['id', 'edit', 'item', 'limpaDados'],
  data() {
    return {
      quantity: '1',
      limpaDadosProdutoAcabado: 0,
      limpaDadosMateriaPrima: 0,
      disabledProdutoAcabado: false,
      disabledProdutoMateriaPrima: true,
      disabledEditProdutoMateriaPrima: false,
      produtoFinalSelect: {
        product_complete_name: '',
        pve_codigo: '',
        pve_codigo_referencia: '',
        pve_codigo_barras: '',
      },
      produtoAcabado: {
        product_complete_name: '',
        pve_codigo: '',
        pve_codigo_referencia: '',
        pve_codigo_barras: '',
      },
      produtoMateriaPrima: {
        pve_codigo: '',
        product_complete_name: '',
        pve_fk_unm_codigo: '',
        measurement_unit: {unm_descricao: ''},
        quantidade: '',
        product_variety: {prv_valor_compra: ''},
        valorTotal: '',
        pve_controla_estoque: true,
        pve_codigo_referencia: '',
        pve_codigo_barras: '',
      },
      produtoItens: [],
      salvaComposicao: [],
      editedItem: {
        pve_codigo: '',
        product_complete_name: '',
        pve_fk_unm_codigo: '',
        measurement_unit: {unm_descricao: ''},
        quantidade: '',
        product_variety: {prv_valor_compra: ''},
        valorTotal: '',
        pve_controla_estoque: '',
        pve_codigo_referencia: '',
        pve_codigo_barras: '',
      },
      defaultItem: {
        pve_codigo: '',
        product_complete_name: '',
        pve_fk_unm_codigo: '',
        measurement_unit: {unm_descricao: ''},
        quantidade: '',
        product_variety: {prv_valor_compra: ''},
        valorTotal: '',
        pve_controla_estoque: true,
        pve_codigo_referencia: '',
        pve_codigo_barras: '',
      },
      editedIndex: -1,
      saveItem: 0,
      total: 0,
      mostraTotal: false,
      dialogDelete: false,
      headers: [
        {text: 'Cód. Produto', value: 'pve_codigo'},
        {text: 'Descrição', value: 'product_complete_name'},
        {text: 'Unidade de medida', value: 'unidadeDeMedida'},
        {text: 'Quantidade', value: 'quantidade', align: 'end', width: '11%'},
        {
          text: 'Valor unitário (R$)',
          value: 'prv_valor_compra',
          align: 'end',
          width: '14%',
        },
        {text: 'Total (R$)', value: 'valorTotal', align: 'end', width: '12%'},
        {text: 'Controla estoque', value: 'pve_controla_estoque', width: '9%'},
        {text: 'Ações', value: 'actions', sortable: false, width: '7%'},
      ],
    };
  },
  watch: {
    edit() {
      if (this.edit != 0) {
        this.quantity = '1';

        if (
          this.item.composition_quantity &&
          this.item.composition_quantity.pcpq_qtde_final_receita
        ) {
          this.quantity = this.item.composition_quantity.pcpq_qtde_final_receita;
        }
        this.produtoFinalSelect = {
          product_complete_name: this.item.product_complete_name,
          pve_codigo: this.item.pve_codigo,
          pve_codigo_referencia: this.item.pve_codigo_referencia,
          pve_codigo_barras: this.item.pve_codigo_barras,
        };
        this.produtoAcabado = {
          product_complete_name: this.item.product_complete_name,
          pve_codigo: this.item.pve_codigo,
          pve_codigo_referencia: this.item.pve_codigo_referencia,
          pve_codigo_barras: this.item.pve_codigo_barras,
        };
        (this.mostraTotal = true), (this.disabledProdutoAcabado = true);
        this.disabledProdutoMateriaPrima = false;
        for (let i = 0; i < this.item.composition.length; i++) {
          this.produtoItens[i] = {
            pve_codigo: this.item.composition[i]
              .pcp_fk_pve_codigo_materia_prima,
            product_complete_name: this.item.composition[i].product_variety_pack
              .product_complete_name,
            unidadeDeMedida: this.item.composition[i].product_variety_pack
              .measurement_unit.unm_descricao,
            quantidade: this.item.composition[i].pcp_quantidade,
            prv_valor_compra: this.item.composition[i].pcp_valor_unitario,
            valorTotal: this.item.composition[i].pcp_total,
            pve_controla_estoque: this.item.composition[i].pcp_controla_estoque,
            pve_codigo_referencia: this.item.composition[i].product_variety_pack
              .pve_codigo_referencia,
            pve_codigo_barras: this.item.composition[i].product_variety_pack
              .pve_codigo_barras,
          };
        }
      }
    },
    'produtoMateriaPrima.quantidade'() {
      if (this.produtoMateriaPrima.quantidade != undefined) {
        this.produtoMateriaPrima.valorTotal =
          this.produtoMateriaPrima.quantidade *
          this.produtoMateriaPrima.product_variety.prv_valor_compra;
        this.produtoMateriaPrima.valorTotal = this.produtoMateriaPrima.valorTotal.toFixed(
          2,
        );
      }
    },
    limpaDados() {
      this.handleCancel();
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.produtoItens;
    },
    getFloatValue(value) {
      return parseFloat((value || '').replace(/[^\d\\.,]/, ''));
    },
    totalValue() {
      let total = 0;
      if (this.produtoItens[0] != null) {
        for (let i = 0; i < this.produtoItens.length; i++) {
          total += parseFloat(this.produtoItens[i].valorTotal);
        }
      }
      return total.toFixed(2);
    },
    valorMonetario(valor) {
      return Number(valor).toFixed(2);
    },
    editItem(item) {
      this.editedIndex = this.produtoItens.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.produtoMateriaPrima = {
        pve_codigo: this.editedItem.pve_codigo,
        product_complete_name: this.editedItem.product_complete_name,
        measurement_unit: {unm_descricao: this.editedItem.unidadeDeMedida},
        quantidade: this.editedItem.quantidade,
        product_variety: {prv_valor_compra: this.editedItem.prv_valor_compra},
        valorTotal: this.editedItem.valorTotal,
        pve_controla_estoque: this.editedItem.pve_controla_estoque,
        pve_codigo_referencia: this.editedItem.pve_codigo_referencia,
        pve_codigo_barras: this.editedItem.pve_codigo_barras,
      };
      this.deleteItemConfirm(item);
      this.disabledEditProdutoMateriaPrima = true;
    },

    deleteItem(item) {
      this.editedIndex = this.produtoItens.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.produtoItens.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.produtoItens[this.editedIndex], this.editedItem);
      } else {
        this.produtoItens.push(this.editedItem);
      }
      this.close();
    },
    funcSalvaComposicao() {
      for (let i = 0; i < this.produtoItens.length; i++) {
        this.salvaComposicao[i] = {
          pcp_fk_pve_codigo_materia_prima: this.produtoItens[i].pve_codigo,
          pcp_quantidade: this.produtoItens[i].quantidade,
          pcp_controla_estoque: this.produtoItens[i].pve_controla_estoque,
          pcp_valor_unitario: this.produtoItens[i].prv_valor_compra,
          pcp_total: this.produtoItens[i].valorTotal,
        };
        if (
          this.produtoItens[i].pve_controla_estoque == true ||
          this.produtoItens[i].pve_controla_estoque == false
        ) {
          if (this.produtoItens[i].pve_controla_estoque == true) {
            this.salvaComposicao[i].pcp_controla_estoque = 1;
          } else if (this.produtoItens[i].pve_controla_estoque == false) {
            this.salvaComposicao[i].pcp_controla_estoque = 0;
          }
        }
      }
    },
    addItem() {
      for (let i = 0; i < this.produtoItens.length; i++) {
        if (
          this.produtoMateriaPrima.pve_codigo == this.produtoItens[i].pve_codigo
        ) {
          swal.fire('', 'Matéria-prima já cadastrada!', 'warning');
          return;
        }
      }
      if (
        this.produtoMateriaPrima.pve_codigo ==
        this.produtoFinalSelect.pve_codigo
      ) {
        swal.fire(
          '',
          'Produto acabado selecionado não pode ser matéria-prima!',
          'warning',
        );
        return;
      } else if (
        this.produtoMateriaPrima.pve_codigo == undefined ||
        this.produtoMateriaPrima.pve_codigo == ''
      ) {
        swal.fire('', 'Por favor, informe uma matéria-prima!', 'warning');
        return;
      } else if (
        this.produtoMateriaPrima.quantidade == undefined ||
        this.produtoMateriaPrima.quantidade == ''
      ) {
        swal.fire('', 'Por favor, informe a quantidade!', 'warning');
        return;
      } else if (!this.edit) {
        if (
          this.produtoMateriaPrima.product_variety.product_mobile.pro_status ==
            0 ||
          this.produtoMateriaPrima.product_variety.prv_status == 0
        ) {
          swal.fire(
            '',
            'A matéria-prima selecionada está inativa. Selecione outra matéria-prima ou altere o status no cadastro do produto.',
            'warning',
          );
          return;
        }
      }

      this.editedItem.pve_codigo = this.produtoMateriaPrima.pve_codigo;
      this.editedItem.product_complete_name = this.produtoMateriaPrima.product_complete_name;
      this.editedItem.unidadeDeMedida = this.produtoMateriaPrima.measurement_unit.unm_descricao;
      this.editedItem.quantidade = this.produtoMateriaPrima.quantidade;
      this.editedItem.prv_valor_compra = this.produtoMateriaPrima.product_variety.prv_valor_compra;
      this.editedItem.pve_controla_estoque = this.produtoMateriaPrima.pve_controla_estoque;
      this.editedItem.valorTotal = this.produtoMateriaPrima.valorTotal;
      this.save();
      this.mostraTotal = true;
      this.produtoMateriaPrima = this.defaultItem;
      this.limpaDadosMateriaPrima += 1;
      this.limpaDadosMateriaPrima += 1;
      this.disabledEditProdutoMateriaPrima = false;
    },
    selectItem() {
      if (
        this.produtoAcabado.pve_codigo == undefined ||
        this.produtoAcabado.pve_codigo == ''
      ) {
        swal.fire('', 'Por favor, informe um produto!', 'warning');
        return;
      } else if (
        this.produtoAcabado.product_variety.product_mobile.pro_status == 0 ||
        this.produtoAcabado.product_variety.prv_status == 0
      ) {
        swal.fire(
          '',
          'O produto selecionado está inativo. Selecione outro produto ou altere o status no cadastro do produto.',
          'warning',
        );
        return;
      }

      if (!this.quantity) {
        swal.fire(
          '',
          'Por favor, informe a quantidade produzida pela receita!',
          'warning',
        );
        return;
      }

      this.produtoFinalSelect = this.produtoAcabado;
      this.disabledProdutoAcabado = true;
      this.disabledProdutoMateriaPrima = false;
    },
    alteraItem() {
      this.disabledProdutoAcabado = false;
      this.disabledProdutoMateriaPrima = true;
      this.produtoFinalSelect.product_complete_name = null;
      this.produtoAcabado = {
        pve_codigo: '',
      };
      this.limpaDadosProdutoAcabado += 1;
      this.limpaDadosProdutoAcabado += 1;
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();

      if (this.produtoItens[0] == null) {
        swal.fire(
          '',
          'Por favor, adicione ao menos uma matéria-prima para continuar',
          'warning',
        );
      } else if (!this.quantity) {
        swal.fire('', 'Por favor, informe a quantidade produzida', 'warning');
      } else {
        this.handleSubmit();
      }
    },
    handleSubmit() {
      this.funcSalvaComposicao();
      const obj = {
        pcp_fk_pve_codigo_acabado: this.produtoFinalSelect.pve_codigo,
        composicao: this.salvaComposicao,
        pcpq_qtde_final_receita: this.quantity,
      };
      swal.fire({
        title: '',
        html: '<span class="mdi mdi-spin mdi-loading"></span>' + ' Salvando...',
        showConfirmButton: false,
      });
      if (this.edit) {
        apiInstance
          .put('/composite-product/' + this.produtoFinalSelect.pve_codigo, obj)
          .then(response => {
            swal
              .fire({
                title: '',
                text: 'Composição atualizada com sucesso !',
                icon: 'success',
                timer: 2000,
              })
              .then(willDelete => {
                if (willDelete) {
                  location.reload();
                }
              });
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        apiInstance
          .post('/composite-product', obj)
          .then(response => {
            swal
              .fire({
                title: '',
                text: 'Composição cadastrada com sucesso !',
                icon: 'success',
                timer: 2000,
              })
              .then(willDelete => {
                if (willDelete) {
                  location.reload();
                }
              });
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    handleCancel() {
      this.produtoFinalSelect = {
        product_complete_name: '',
        pve_codigo: '',
        pve_codigo_referencia: '',
        pve_codigo_barras: '',
      };
      this.produtoAcabado = {
        product_complete_name: '',
        pve_codigo: '',
        pve_codigo_referencia: '',
        pve_codigo_barras: '',
      };
      this.produtoMateriaPrima = {
        pve_codigo: '',
        product_complete_name: '',
        pve_fk_unm_codigo: '',
        measurement_unit: {unm_descricao: ''},
        quantidade: '',
        product_variety: {prv_valor_compra: ''},
        valorTotal: '',
        pve_controla_estoque: 1,
        pve_codigo_referencia: '',
        pve_codigo_barras: '',
      };
      this.produtoItens = [];
      this.salvaComposicao = [];
      this.editedItem = {
        pve_codigo: '',
        product_complete_name: '',
        unidadeDeMedida: '',
        quantidade: '',
        prv_valor_compra: '',
        valorTotal: '',
        pve_controla_estoque: '',
      };
      this.disabledProdutoAcabado = false;
      this.disabledProdutoMateriaPrima = true;
    },
  },
};
</script>

<style scoped>
#Modal {
}

.modal__title {
  text-align: rigth;
  font-size: 20px;
}

.table-padding {
  padding-bottom: 1em;
}

input:focus {
  /*border-color: #28a745 !important;*/
  box-shadow: none !important;
}

.btn-table-inner {
  display: inline-block;
  width: 2.5em;
  height: 2.5em;
}

.btn-table-inner-delete {
  color: #ffffff;
  border: solid 1px #dc3545;
  border-radius: 0.25rem;
  background: #dc3545;
}

.btn-table-inner-delete:hover {
  color: #dc3545;
  border-color: #dc3545;
  background-color: #ffffff;
  cursor: pointer;
}

.icon-table-inner {
  fill: currentColor;
  width: 2em;
  height: 2em;
  margin: 0.25em;
}

.btn-push-option {
  display: flex;
  align-items: flex-end;
  justify-items: flex-end;
}

.formBordas {
  border: solid medium rgba(0, 0, 0, 0.5);
  padding: 10px;
}

.submit {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 10px;
}

.botaoAlterar {
  margin-top: 37px;
}

.checkbox {
  margin: auto;
  padding: 10px 0px 0px 40px;
}

.align-right {
  text-align: right;
}

.campoObrigatorio >>> div.v-input__slot {
  padding: 0px 12px 0px 0px !important;
}

.right-input >>> input {
  text-align: right;
}

.align-center {
  text-align: center;
}
</style>
